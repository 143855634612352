import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Button, Row, Col, message } from 'antd'
import { MutationFunction } from '@apollo/client'

import { FORM_ITEM_RULES } from '@constants/constForm'
import { MFormItemWrapper, MFormItemInput } from 'components'
import { MConfirmModal } from 'components/modals'
import MMutation from 'components/MMutation'
import { MOuterCard, Text14Normal } from 'components/@atomics'
import { MFormItemInputProps } from 'components/formItems/MFormItemInput'
import { TypeUseModalProps } from 'containers/hooks/useModal'

export type FormManagementInfoData = {
  notificationManagerName: string
  notificationEmail: string
  notificationMobileNumber: string
}

type ManagerInfoButtonSetProps = Pick<FormManagementInfoProps, 'useModalProps'> & {
  needEdit: boolean
  setNeedEdit: Dispatch<SetStateAction<boolean>>
}

export type UpdateSellerNotificationVariables = {
  id: string
  input: FormManagementInfoData
}

type FormManagementInfoProps = {
  data: FormManagementInfoData
  needSubmit?: boolean
  useModalProps?: TypeUseModalProps<any>
  isAddPage?: boolean
}

const NOTIFICATION_MANAGER_NAME_PROPS = ({ isRequired = false }: { isRequired?: boolean }) => {
  return {
    label: '담당자명',
    name: 'notificationManagerName',
    rules: isRequired ? [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED] : [],
  }
}

const NOTIFICATION_EMAIL_PROPS = ({ isRequired = false }: { isRequired?: boolean }) => {
  return {
    label: '이메일',
    name: 'notificationEmail',
    rules: isRequired
      ? [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_EMAIL]
      : [FORM_ITEM_RULES.FORM_ITEM_RULE_EMAIL],
  }
}
const NOTIFICATION_MOBILE_NUMBER_PROPS = ({ isRequired = false }: { isRequired?: boolean }) => {
  return {
    label: '전화번호',
    name: 'notificationMobileNumber',
    rules: isRequired
      ? [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_PHONE_NUMBER]
      : [FORM_ITEM_RULES.FORM_ITEM_RULE_PHONE_NUMBER],
  }
}

// Input Set
const ManagerInfoInputSet: FC<Pick<FormManagementInfoProps, 'isAddPage'>> = ({ isAddPage }) => {
  const defaultInputProps: MFormItemInputProps = {
    labelCol: { span: 4 },
  }

  return (
    <MOuterCard title={!isAddPage ? '' : undefined} requiredMark={!isAddPage} style={{ margin: 0 }}>
      <MFormItemInput {...NOTIFICATION_MANAGER_NAME_PROPS({ isRequired: !isAddPage })} {...defaultInputProps} />
      <MFormItemInput {...NOTIFICATION_EMAIL_PROPS({ isRequired: !isAddPage })} {...defaultInputProps} />
      <MFormItemInput {...NOTIFICATION_MOBILE_NUMBER_PROPS({ isRequired: !isAddPage })} {...defaultInputProps} />
    </MOuterCard>
  )
}

// Button Set
const ManagerInfoButtonSet: FC<ManagerInfoButtonSetProps> = ({ setNeedEdit, needEdit, useModalProps }) => {
  if (!needEdit) return <></>
  return (
    <Row justify="center" gutter={[10, 0]} style={{ marginTop: 20 }}>
      <Col>
        <Button
          onClick={() => {
            setNeedEdit(!needEdit)
          }}
        >
          취소
        </Button>
      </Col>
      <Col>
        <MMutation
          gqlKey="updateSellerNotification"
          onAPISuccess={() => {
            message.success('운영 담당 정보가 변경되었습니다.')
            setNeedEdit(false)
          }}
        >
          {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
            return (
              <>
                <Button htmlType="submit" type="primary">
                  변경 저장
                </Button>
                {/* TODO yoon: ModalConfirm 으로 변경 필요 */}
                <MConfirmModal
                  useModalProps={useModalProps!}
                  fixedProps={{
                    title: '운영 담당 정보 수정',
                    description: '운영 담당 정보를 수정하시겠습니까?',
                  }}
                  onAction={() => {
                    const { variables }: { variables: UpdateSellerNotificationVariables } = useModalProps?.data
                    mutation({ variables })
                  }}
                  loading={loading}
                />
              </>
            )
          }}
        </MMutation>
      </Col>
    </Row>
  )
}

const FormManagementInfo: FC<FormManagementInfoProps> = ({ data, needSubmit, useModalProps, isAddPage }) => {
  const [needEdit, setNeedEdit] = useState<boolean>(false)

  const infoItemDefaultProps: MFormItemInputProps = { labelCol: { span: 4 }, required: false }

  return (
    <MOuterCard title="운영 담당 정보" requiredText="주문 및 문의 관련 알림이 전달됩니다.">
      {!isAddPage && (
        <>
          {/* Edit Button */}
          <Button type="primary" onClick={() => setNeedEdit(!needEdit)} style={{ width: 80, marginBottom: 20 }}>
            수정
          </Button>

          {/* Info Set */}
          <MFormItemWrapper
            label={NOTIFICATION_MANAGER_NAME_PROPS({ isRequired: infoItemDefaultProps.required }).label}
            {...infoItemDefaultProps}
          >
            <Text14Normal>{data?.notificationManagerName ?? '-'}</Text14Normal>
          </MFormItemWrapper>
          <MFormItemWrapper
            label={NOTIFICATION_EMAIL_PROPS({ isRequired: infoItemDefaultProps.required }).label}
            {...infoItemDefaultProps}
          >
            <Text14Normal>{data?.notificationEmail ?? '-'}</Text14Normal>
          </MFormItemWrapper>
          <MFormItemWrapper
            label={NOTIFICATION_MOBILE_NUMBER_PROPS({ isRequired: infoItemDefaultProps.required }).label}
            {...infoItemDefaultProps}
          >
            <Text14Normal>{data?.notificationMobileNumber ?? '-'}</Text14Normal>
          </MFormItemWrapper>
        </>
      )}

      {/* Input Set */}
      {((needEdit && !isAddPage) || isAddPage) && <ManagerInfoInputSet isAddPage={isAddPage} />}

      {/* Submit Button Set */}
      {needSubmit && (
        <ManagerInfoButtonSet needEdit={needEdit} setNeedEdit={setNeedEdit} useModalProps={useModalProps} />
      )}
    </MOuterCard>
  )
}

export default FormManagementInfo
