import _ from 'lodash'
import * as utilFormValidators from '@libs/utils/utilFormValidators'
import * as utilData from '@libs/utils/utilData'
import * as utilApi from '@libs/utils/utilApi'
import { Col, Form, Input, Row, Statistic } from 'antd'
import { MButton, Text14Normal, Text14Red } from 'components/@atomics'
import { MConfirmModal, MFormItemInput } from 'components'
import { CERT_TYPE, FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import useModal from 'containers/hooks/useModal'
import { FORM_ITEM_RULES } from '@constants/constForm'
import { useRef, useState } from 'react'
import { MutationFunction } from '@apollo/client'
import { IModelUserCertification } from 'containers/models/modelUserCertification'
import { Moment } from 'moment/moment'
import MMutation from 'components/MMutation'
import { IModelUserInfo } from 'containers/models/modelUserInfo'

type TypeFormCertNumberProps = {
  certType: CERT_TYPE
  onConfirmed?: (isConfirmed: boolean, resultData: TypeConfirmedUserCertNumberResult) => void
}

type TypeConfirmUserCertNumberResult = {
  userInfo?: { data: IModelUserInfo }
  userCertification: { data: IModelUserCertification }
}

export type TypeConfirmedUserCertNumberResult = {
  userInfo?: IModelUserInfo
  userCertification: IModelUserCertification
}

const FORM_ITEM_PROPS_MOBILE_NUMBER = {
  label: FIELD_VALUES.FIELD_MOBILE_NUMBER,
  name: FIELD_KEYS.FIELD_MOBILE_NUMBER,
  placeholder: '휴대폰 번호를 입력해주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_MOBILE_NUMBER],
}

const FORM_ITEM_PROPS_EMAIL = {
  label: FIELD_VALUES.FIELD_EMAIL,
  name: FIELD_KEYS.FIELD_EMAIL,
  placeholder: '이메일을 입력해주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_EMAIL],
}

const FORM_ITEM_PROPS_MOBILE_CONFIRM_NUMBER = {
  name: FIELD_KEYS.FIELD_MOBILE_CONFIRM_NUMBER,
  placeholder: '인증번호 6자리',
  wrapperCol: { span: 24 },
  maxLength: 6,
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
}

const FormCertNumber = ({ certType, onConfirmed }: TypeFormCertNumberProps) => {
  const inputFormItemProps =
    certType === CERT_TYPE.RESET_PASSWORD_BY_EMAIL ? FORM_ITEM_PROPS_EMAIL : FORM_ITEM_PROPS_MOBILE_NUMBER

  // 핸드폰 번호 입력 form
  const { useModalProps } = useModal()
  const [formSendUserCertNumber] = Form.useForm()
  const [isValidInput, setIsValidInput] = useState(false)
  const [isSendUserCertNumber, setIsSendUserCertNumber] = useState(false)
  const [sendUserCertNumberResult, setSendUserCertNumberResult] = useState<IModelUserCertification>()

  // 인증번호 확인 form
  const [formConfirmUserCertNumber] = Form.useForm()
  const refCertNumberInput = useRef<Input>(null)
  const [isValidCertNumber, setIsValidCertNumber] = useState(false)
  const [userCertificationExpiredAt, setUserCertificationExpiredAt] = useState<Moment>()

  return (
    <>
      {/* 핸드폰 번호 입력 form */}
      <MMutation
        gqlKey="sendUserCertNumber"
        onAPISuccess={(result) => {
          // 기존 입력된 인증번호 초기화
          formConfirmUserCertNumber.resetFields([FORM_ITEM_PROPS_MOBILE_CONFIRM_NUMBER.name])

          setTimeout(async () => {
            setIsSendUserCertNumber(true)
          }, 500)

          // @ts-ignore - resultType을 재정의 하기 위해서 ignore함
          const { userCertification }: { userCertification: { data: IModelUserCertification } } = result
          setSendUserCertNumberResult(userCertification.data)
          setUserCertificationExpiredAt(userCertification.data.expiredAt)

          if (refCertNumberInput.current) {
            refCertNumberInput.current.focus()
          }
        }}
      >
        {(mutation: MutationFunction, { loading, error }: { loading: boolean; error: { message?: string } }) => {
          return (
            <Form
              id="formSendCertNumber"
              form={formSendUserCertNumber}
              size="large"
              layout="vertical"
              requiredMark={false}
            >
              <MFormItemInput
                style={{ marginTop: 28, marginBottom: 15 }}
                inputStyle={{ height: 50 }}
                extra={error && <Text14Red>{utilApi.getErrorMessage(error.message)}</Text14Red>}
                onChange={(e) => {
                  setIsValidInput(
                    certType === CERT_TYPE.RESET_PASSWORD_BY_EMAIL
                      ? utilFormValidators.isValidEmailFormat(e.target.value)
                      : utilFormValidators.isValidMobileNumberFormat(e.target.value)
                  )
                }}
                {...inputFormItemProps}
              />
              <MButton
                theme="black"
                width="100%"
                height={50}
                disabled={!isValidInput && error === undefined}
                fontSize={{ xs: 14, md: 18 }}
                onClick={() => {
                  useModalProps.showModal()
                }}
              >
                {isSendUserCertNumber ? '인증번호 재전송' : '인증번호 전송'}
              </MButton>

              {/* TODO yoon: ModalConfirm 으로 변경 필요 */}
              <MConfirmModal
                useModalProps={useModalProps}
                fixedProps={{
                  theme: 'blackAndWhite',
                  closable: true,
                  description: (
                    <Text14Normal>
                      {certType === CERT_TYPE.RESET_PASSWORD_BY_EMAIL
                        ? formSendUserCertNumber.getFieldValue(inputFormItemProps.name)
                        : utilData.formatPhoneNumber(formSendUserCertNumber.getFieldValue(inputFormItemProps.name))}
                      로 인증번호를 전송합니다.
                    </Text14Normal>
                  ),
                }}
                onAction={async () => {
                  const inputValue = formSendUserCertNumber.getFieldValue(inputFormItemProps.name)
                  const variables = { input: { certType } }
                  if (certType === CERT_TYPE.RESET_PASSWORD_BY_EMAIL) {
                    _.set(variables, ['input', 'email'], inputValue)
                  } else {
                    _.set(variables, ['input', 'mobileNumber'], inputValue)
                  }
                  await mutation({ variables })
                }}
                loading={loading}
              />
            </Form>
          )
        }}
      </MMutation>

      {/* 인증번호 확인 form */}
      <MMutation
        gqlKey="confirmUserCertNumber"
        // @ts-ignore - result type 정의를 했기 때문에 ignore 함
        onAPISuccess={(result: TypeConfirmUserCertNumberResult) => {
          const { userInfo, userCertification } = result
          setUserCertificationExpiredAt(undefined)

          if (onConfirmed) {
            onConfirmed(true, { userInfo: userInfo?.data, userCertification: userCertification.data })
          }
        }}
      >
        {(mutation: MutationFunction, { loading, error }: { loading: boolean; error: { message?: string } }) => {
          return (
            <Form
              id="formCertNumber"
              size="large"
              layout="vertical"
              requiredMark={false}
              form={formConfirmUserCertNumber}
              onFinish={async (values: { mobileConfirmNumber: string }) => {
                await mutation({
                  variables: {
                    id: sendUserCertNumberResult!.id,
                    input: { confirmNumber: values.mobileConfirmNumber },
                  },
                })
              }}
            >
              <Row justify="space-between" style={{ paddingTop: 28 }}>
                <Col span={15}>
                  <MFormItemInput
                    inputRef={refCertNumberInput}
                    disabled={!isSendUserCertNumber && error === undefined}
                    onChange={(e) => {
                      setIsValidCertNumber(e.target.value.length === FORM_ITEM_PROPS_MOBILE_CONFIRM_NUMBER.maxLength)
                    }}
                    suffix={
                      <Statistic.Countdown
                        format="mm:ss"
                        // @ts-ignore - Statistic.Countdown에서 Moment type을 허용 안함. 하지만 공식 문서에서는 Moment는 쓸 수 있다고 함
                        value={userCertificationExpiredAt}
                        valueStyle={{ fontSize: 14 }}
                        onFinish={() => {
                          setUserCertificationExpiredAt(undefined)
                        }}
                      />
                    }
                    extra={error && <Text14Red>{utilApi.getErrorMessage(error.message)}</Text14Red>}
                    {...FORM_ITEM_PROPS_MOBILE_CONFIRM_NUMBER}
                  />
                </Col>
                <Col span={8}>
                  <MButton
                    form="formCertNumber"
                    disabled={!isValidCertNumber && error === undefined}
                    loading={loading}
                    theme="black"
                    height={40}
                    width="100%"
                    fontSize={14}
                    onClick={(e) => {
                      e.preventDefault()
                      formConfirmUserCertNumber.submit()
                    }}
                  >
                    인증번호 확인
                  </MButton>
                </Col>
              </Row>
            </Form>
          )
        }}
      </MMutation>
    </>
  )
}

export default FormCertNumber
