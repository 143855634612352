import { FORM_ITEM_RULES } from '@constants/constForm'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import * as utilData from '@libs/utils/utilData'
import { RootState } from '@libs/redux/store'
import { USER_MODE_TYPE } from '@constants/constDomain'
import { FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import { MFormItemFileUpload, MFormItemInput, MFormItemSelect } from 'components/formItems'
import { MOuterCard, Text12Red } from 'components/@atomics'
import { optionType } from 'containers/models/modelInitData'
import MFormRadioYnWithDatePicker from 'components/formItems/MFormItemLimitedDatePicker'
import { FormInstance } from 'antd/lib/form'

type FormBankAccountProps = {
  form: FormInstance
  userMode: USER_MODE_TYPE
  bankBookImage?: string
  disabled?: boolean
}

const FORM_ITEM_PROPS_ACCOUNT_HOLDER_NAME = () => {
  return {
    label: FIELD_VALUES.FIELD_ACCOUNT_HOLDER_NAME,
    name: FIELD_KEYS.FIELD_ACCOUNT_HOLDER_NAME,
    rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
  }
}

const FORM_ITEM_PROPS_BANK_NAME = ({ selectOptions }: { selectOptions: optionType[] }) => {
  return {
    label: FIELD_VALUES.FIELD_BANK_CODE,
    name: FIELD_KEYS.FIELD_BANK_CODE,
    required: true,
    rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
    selectOptions,
  }
}

const FORM_ITEM_PROPS_ACCOUNT_NUMBER = () => {
  return {
    label: FIELD_VALUES.FIELD_ACCOUNT_NUMBER,
    name: FIELD_KEYS.FIELD_ACCOUNT_NUMBER,
    rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
  }
}

const FORM_ITEM_PROPS_BANK_BOOK_IMAGE = () => {
  return {
    label: FIELD_VALUES.FIELD_BANK_BOOK_IMAGE,
    name: FIELD_KEYS.FIELD_BANK_BOOK_IMAGE,
    accept: 'image/*',
    required: true,
    rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
  }
}

const FORM_ITEM_PROPS_ACCOUNT_MANAGER_NAME = () => {
  return {
    label: FIELD_VALUES.FIELD_ACCOUNT_MANAGER_NAME,
    name: FIELD_KEYS.FIELD_ACCOUNT_MANAGER_NAME,
    rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
  }
}

const FORM_ITEM_PROPS_TAX_EMAIL = () => {
  return {
    label: FIELD_VALUES.FIELD_TAX_EMAIL,
    name: FIELD_KEYS.FIELD_TAX_EMAIL,
    rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_EMAIL],
  }
}

const FORM_ITEM_PROPS_ACCOUNT_MANAGER_TELEPHONE_NUMBER = () => {
  return {
    label: FIELD_VALUES.FIELD_ACCOUNT_MANAGER_TELEPHONE_NUMBER,
    name: FIELD_KEYS.FIELD_ACCOUNT_MANAGER_TELEPHONE_NUMBER,
    rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
  }
}

const FORM_ITEM_PROPS_REGISTRATION_NUMBER = () => {
  return {
    label: FIELD_VALUES.FIELD_REGISTRATION_NUMBER,
    name: FIELD_KEYS.FIELD_REGISTRATION_NUMBER,
  }
}

const FORM_ITEM_PROPS_AGENT_REPORT = () => {
  return {
    formItemProps: {
      label: FIELD_VALUES.FIELD_AGENT_REPORT_YN,
      required: true,
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    },
    radioProps: {
      name: FIELD_KEYS.FIELD_AGENT_REPORT_YN,
      options: [
        { label: 'No', value: false },
        { label: 'Yes', value: true },
      ],
    },
    datePickerProps: {
      name: FIELD_KEYS.FIELD_AGENT_REPORTED_AT,
      format: 'YYYY-MM',
      picker: 'month',
      placeholder: 'YYYY-MM',
    },
  }
}

const FormBankAccount: FC<FormBankAccountProps> = ({ form, userMode, disabled = false }) => {
  const initData = useSelector((state: RootState) => state.reduxDataReducers.initData)
  if (!utilData.hasData(initData)) return null
  return (
    <MOuterCard title="정산 정보" requiredMark>
      <MFormItemInput disabled={disabled} {...FORM_ITEM_PROPS_ACCOUNT_HOLDER_NAME()} />
      <MFormItemSelect
        disabled={disabled}
        {...FORM_ITEM_PROPS_BANK_NAME({
          selectOptions: initData.bankSelectOptions as optionType[],
        })}
      />
      <MFormItemInput disabled={disabled} {...FORM_ITEM_PROPS_ACCOUNT_NUMBER()} />
      <MFormItemFileUpload disabled={disabled} {...FORM_ITEM_PROPS_BANK_BOOK_IMAGE()} />
      {userMode === USER_MODE_TYPE.ADMIN && (
        <MFormItemInput
          disabled={disabled}
          extra={<Text12Red>*원천 징수를 위해 필요한 정보입니다.</Text12Red>}
          {...FORM_ITEM_PROPS_REGISTRATION_NUMBER()}
        />
      )}
      {userMode === USER_MODE_TYPE.SELLER && (
        <>
          <MFormItemInput disabled={disabled} {...FORM_ITEM_PROPS_ACCOUNT_MANAGER_NAME()} />
          <MFormItemInput disabled={disabled} {...FORM_ITEM_PROPS_TAX_EMAIL()} />
          <MFormItemInput disabled={disabled} {...FORM_ITEM_PROPS_ACCOUNT_MANAGER_TELEPHONE_NUMBER()} />
        </>
      )}
      <MFormRadioYnWithDatePicker form={form} {...FORM_ITEM_PROPS_AGENT_REPORT()} />
    </MOuterCard>
  )
}
export default FormBankAccount
