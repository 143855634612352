import useModal from 'containers/hooks/useModal'
import { useState } from 'react'
import { MutationFunction } from '@apollo/client'
import { Col, Divider, Form, Row } from 'antd'
import { useRouter } from 'next/router'
import * as utilFormValidators from '@libs/utils/utilFormValidators'
import { getErrorMessage } from '@libs/utils/utilApi'
import { CERT_TYPE, FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import { FORM_ITEM_RULES } from '@constants/constForm'
import ROUTES from '@constants/constRoutes'
import { themes } from '@constants'
import {
  Icon,
  MButton,
  MLinkButton,
  Text12Grey81,
  Text14G97,
  Text14Normal,
  Text14Red,
  Text20Normal,
  Text40Normal,
} from 'components/@atomics'
import MFormItemInput from 'components/formItems/MFormItemInput'
import { MModal } from 'components/modals'
import MMutation from 'components/MMutation'
import FormCertNumber, { TypeConfirmedUserCertNumberResult } from 'domains/common/forms/FormCertNumber'
import styled from 'styled-components'

const FORM_ITEM_PROPS_PASSWORD = {
  type: FIELD_KEYS.FIELD_PASSWORD,
  name: FIELD_KEYS.FIELD_NEW_PASSWORD,
  label: FIELD_VALUES.FIELD_NEW_PASSWORD,
  placeholder: '새로운 비밀번호를 입력해 주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_PASSWORD_FORMAT],
}

const FORM_ITEM_PROPS_CONFIRM_PASSWORD = {
  type: FIELD_KEYS.FIELD_PASSWORD,
  name: FIELD_KEYS.FIELD_CONFIRM_PASSWORD,
  label: `${FIELD_VALUES.FIELD_NEW_PASSWORD} 확인`,
  placeholder: '새로운 비밀번호를 다시한번 입력해 주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_CONFIRM_PASSWORD],
}

enum RenderType {
  CHOOSE_CERT_METHOD = 'CHOOSE_CERT_METHOD',
  SHOW_EMAIL_ACCOUNT = 'SHOW_EMAIL_ACCOUNT',
  RESET_PASSWORD_BY_MOBILE_NUMBER = 'RESET_PASSWORD_BY_MOBILE_NUMBER',
  RESET_PASSWORD_BY_EMAIL = 'RESET_PASSWORD_BY_EMAIL',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

const FormChooseCertMethod = ({ setRenderType }: { setRenderType: Function }) => {
  const router = useRouter()
  return (
    <>
      <Row justify="center" style={{ paddingTop: 28 }}>
        <Col>
          <Text40Normal bold>인증 방법 선택</Text40Normal>
        </Col>
      </Row>
      <Row style={{ paddingTop: 68 }}>
        <Col span={24}>
          <MButton
            theme="white"
            width="100%"
            height={80}
            onClick={() => {
              setRenderType(RenderType.RESET_PASSWORD_BY_MOBILE_NUMBER)
            }}
          >
            <Row align="middle">
              <Col style={{ textAlign: 'left' }} span={23}>
                <Text20Normal>휴대폰 번호로 찾기</Text20Normal>
              </Col>
              <Col span={1}>
                <Icon icon="chevron-right" />
              </Col>
            </Row>
          </MButton>
        </Col>
      </Row>
      <Row style={{ paddingTop: 12 }}>
        <Col span={24}>
          <MButton
            theme="white"
            width="100%"
            height={80}
            onClick={() => {
              setRenderType(RenderType.RESET_PASSWORD_BY_EMAIL)
            }}
          >
            <Row align="middle">
              <Col style={{ textAlign: 'left' }} span={23}>
                <Text20Normal>이메일로 찾기</Text20Normal>
              </Col>
              <Col span={1}>
                <Icon icon="chevron-right" />
              </Col>
            </Row>
          </MButton>
        </Col>
      </Row>

      <Row style={{ paddingTop: 40 }}>
        <Col span={24}>
          <MButton
            theme="white"
            width="100%"
            height={50}
            fontSize={18}
            onClick={() => {
              router.back()
            }}
          >
            이전으로 돌아가기
          </MButton>
        </Col>
      </Row>
    </>
  )
}

const FormResetPassword = ({ userInfo, userCertification }: TypeConfirmedUserCertNumberResult) => {
  const [form] = Form.useForm()
  const router = useRouter()
  const { useModalProps } = useModal()
  const [isBtnResetPasswordEnabled, setIsBtnResetPasswordEnabled] = useState<boolean>(false)
  return (
    <>
      <Row justify="center" style={{ paddingTop: 28, paddingBottom: 40 }}>
        <Col>
          <Text40Normal bold>비밀번호 재설정</Text40Normal>
        </Col>
      </Row>
      <MMutation
        gqlKey="resetPassword"
        onAPISuccess={() => {
          useModalProps.showModal()
        }}
      >
        {(
          mutation: MutationFunction,
          { loading, error: errorResetPassword }: { loading: boolean; error: { message?: string } }
        ) => {
          return (
            <Form
              form={form}
              size="large"
              scrollToFirstError
              layout="vertical"
              requiredMark={false}
              onValuesChange={(values) => {
                const newPassword = values[FORM_ITEM_PROPS_PASSWORD.name]
                  ? values[FORM_ITEM_PROPS_PASSWORD.name]
                  : form.getFieldValue(FORM_ITEM_PROPS_PASSWORD.name)
                const newPasswordConfirm = values[FORM_ITEM_PROPS_CONFIRM_PASSWORD.name]
                  ? values[FORM_ITEM_PROPS_CONFIRM_PASSWORD.name]
                  : form.getFieldValue(FORM_ITEM_PROPS_CONFIRM_PASSWORD.name)

                setIsBtnResetPasswordEnabled(
                  newPassword &&
                    newPassword.length > 0 &&
                    utilFormValidators.isValidPasswordFormat(newPassword) &&
                    newPassword === newPasswordConfirm
                )
              }}
              onFinish={async (values: any) => {
                await mutation({
                  variables: {
                    id: userInfo!.id,
                    input: {
                      userCertification: userCertification.id,
                      newPassword: values.newPassword,
                    },
                  },
                })
              }}
            >
              <MFormItemInput style={{ marginBottom: 0 }} inputStyle={{ height: 50 }} {...FORM_ITEM_PROPS_PASSWORD} />
              <Row style={{ marginBottom: 8 }}>
                <Col>
                  <Text14G97>· 영문대/소문자,숫자,특수문자 중 2가지 이상 조합, 8~16자 이내</Text14G97>
                </Col>
              </Row>
              <MFormItemInput inputStyle={{ height: 50 }} {...FORM_ITEM_PROPS_CONFIRM_PASSWORD} />
              <Row justify="center" hidden={errorResetPassword?.message === undefined} style={{ marginBottom: 19 }}>
                <Col>
                  <Text14Red>{getErrorMessage(errorResetPassword?.message)}</Text14Red>
                </Col>
              </Row>
              <Row justify="center" style={{ paddingTop: 16 }}>
                <Col span={24}>
                  <MButton
                    htmlType="submit"
                    theme="black"
                    width="100%"
                    height={70}
                    fontSize={{ xs: 14, md: 18 }}
                    disabled={!isBtnResetPasswordEnabled}
                    loading={loading}
                  >
                    비밀번호 재설정
                  </MButton>
                </Col>
              </Row>
            </Form>
          )
        }}
      </MMutation>
      <MModal
        useModalProps={useModalProps}
        theme="blackAndWhite"
        width={360}
        footerType="MODAL_FOOTER_OK"
        ComponentContent={
          <Text14Normal textAlign="center">
            {'새로운 비밀번호가 설정되었습니다.\n확인 버튼을 눌러 로그인 화면으로 이동합니다.'}
          </Text14Normal>
        }
        onAction={() => {
          router.push(ROUTES.LOGIN)
          useModalProps.hideModal()
        }}
      />
    </>
  )
}

const FormFindPassword = () => {
  const [renderType, setRenderType] = useState<RenderType>(RenderType.CHOOSE_CERT_METHOD)
  const [confirmedUserCertNumberResult, setConfirmedUserCertNumberResult] =
    useState<TypeConfirmedUserCertNumberResult>()

  const RenderForm = () => {
    switch (renderType) {
      case RenderType.CHOOSE_CERT_METHOD:
        return <FormChooseCertMethod setRenderType={setRenderType} />
      case RenderType.RESET_PASSWORD_BY_MOBILE_NUMBER:
      case RenderType.RESET_PASSWORD_BY_EMAIL:
        return (
          <>
            <Row justify="center" style={{ paddingTop: 28, paddingBottom: 40 }}>
              <Col>
                <Text40Normal bold>인증 번호 확인</Text40Normal>
              </Col>
            </Row>
            <FormCertNumber
              certType={
                renderType as unknown as CERT_TYPE.RESET_PASSWORD_BY_MOBILE_NUMBER | CERT_TYPE.RESET_PASSWORD_BY_EMAIL
              }
              onConfirmed={(isConfirmed, resultData) => {
                if (isConfirmed) {
                  setConfirmedUserCertNumberResult(resultData)
                  setRenderType(RenderType.SHOW_EMAIL_ACCOUNT)
                }
              }}
            />
          </>
        )
      case RenderType.SHOW_EMAIL_ACCOUNT:
        return (
          <>
            <Row justify="center" style={{ paddingTop: 28, paddingBottom: 40 }}>
              <Col>
                <Text40Normal bold>아이디 확인</Text40Normal>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Text14Normal bold>회원님의 아이디는 아래와 같습니다.</Text14Normal>
              </Col>
            </Row>
            <Row justify="center" style={{ paddingTop: 28 }}>
              <StyledBorderCol span={24}>
                <Text20Normal>{confirmedUserCertNumberResult!.userInfo!.email}</Text20Normal>
              </StyledBorderCol>
            </Row>
            <Row style={{ paddingTop: 40 }}>
              <Col span={24}>
                <MButton
                  theme="white"
                  width="100%"
                  height={50}
                  fontSize={18}
                  onClick={() => {
                    setRenderType(RenderType.RESET_PASSWORD)
                  }}
                >
                  비밀번호 재설정
                </MButton>
              </Col>
            </Row>
          </>
        )
      case RenderType.RESET_PASSWORD:
        return (
          <FormResetPassword
            userInfo={confirmedUserCertNumberResult!.userInfo}
            userCertification={confirmedUserCertNumberResult!.userCertification}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <RenderForm />
      <Row justify="center" style={{ paddingTop: 48 }}>
        <Col>
          <MLinkButton url={ROUTES.FIND_ID} textUnderline={false}>
            <Text12Grey81>아이디 찾기</Text12Grey81>
          </MLinkButton>
        </Col>
        <Col>
          <Divider type="vertical" style={{ borderColor: themes.colors.grey55 }} />
        </Col>
        <Col>
          <MLinkButton url={ROUTES.LOGIN} textUnderline={false}>
            <Text12Grey81>로그인 하기</Text12Grey81>
          </MLinkButton>
        </Col>
      </Row>
    </>
  )
}

const StyledBorderCol = styled(Col)`
  border: 2px solid ${themes.colors.black33};
  height: 70px;
  line-height: 70px;
  text-align: center;
`

export default FormFindPassword
