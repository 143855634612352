import { useRef, useState } from 'react'
import { MutationFunction, useMutation } from '@apollo/client'
import { Col, Divider, Form, Input, Row, Statistic } from 'antd'
import styled from 'styled-components'

import * as utilData from '@libs/utils/utilData'
import * as utilFormValidators from '@libs/utils/utilFormValidators'
import ROUTES from '@constants/constRoutes'
import { themes } from '@constants'
import { getErrorMessage } from '@libs/utils/utilApi'
import { CERT_TYPE, FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import { FORM_ITEM_RULES } from '@constants/constForm'
import {
  MButton,
  MLinkButton,
  Text12Grey81,
  Text14Normal,
  Text14Red,
  Text18Normal,
  Text20Normal,
  Text40Normal,
} from 'components/@atomics'
import MFormItemInput from 'components/formItems/MFormItemInput'
import { MConfirmModal } from 'components/modals'
import useModal from 'containers/hooks/useModal'
import { IModelUserCertification } from 'containers/models/modelUserCertification'
import MMutation from 'components/MMutation'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import USER_MUTATION_GQLS from 'containers/gqls/accounts/user/mutations'

const FORM_ITEM_PROPS_USERNAME = {
  label: FIELD_VALUES.FIELD_NAME,
  name: FIELD_KEYS.FIELD_NAME,
  placeholder: '이름을 입력해 주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
}

const FORM_ITEM_PROPS_MOBILE_NUMBER = {
  label: FIELD_VALUES.FIELD_MOBILE_NUMBER,
  name: FIELD_KEYS.FIELD_MOBILE_NUMBER,
  placeholder: '휴대폰 번호를 입력해주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_MOBILE_NUMBER],
}

const FORM_ITEM_PROPS_MOBILE_CONFIRM_NUMBER = {
  name: FIELD_KEYS.FIELD_MOBILE_CONFIRM_NUMBER,
  placeholder: '인증번호 6자리',
  wrapperCol: { span: 24 },
  maxLength: 6,
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
}

enum RenderType {
  FIND_ID = 'FIND_ID',
  FOUND_ID = 'FOUND_ID',
}

const FormFindId = () => {
  const [renderType, setRenderType] = useState<RenderType>(RenderType.FIND_ID)

  // 사용자 정보 입력 form
  const [formOfNameMobileNumber] = Form.useForm()
  const { useModalProps: useConfirmSendCertModalProps } = useModal()
  const [isValidMobileNumber, setIsValidMobileNumber] = useState(false)
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false)
  const [isCertNumberSent, setIsCertNumberSent] = useState(false)
  const [userCertification, setUserCertification] = useState<IModelUserCertification>()

  // 인증번호 입력 form
  const [formOfCertNumber] = Form.useForm()
  const [isCertButtonDisabled, setIsCertButtonDisabled] = useState(true)
  const refCertNumberInput = useRef<Input>(null)
  const [serverErrorMessage, setServerErrorMessage] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [userEmail, setUserEmail] = useState<string>('')

  // 회원 인증 번호 발송 요청
  const [sendUserCertNumberMutation, { loading: sendUserCertNumberLoading }] = useMutation(
    USER_MUTATION_GQLS.SEND_USER_CERT_NUMBER,
    {
      onCompleted: (data) => {
        setIsCertNumberSent(true)
        setTimeout(async () => {
          setIsSendButtonDisabled(false)
        }, 3000)
        setUserCertification(data.sendUserCertNumber.userCertification)
        if (refCertNumberInput.current) {
          refCertNumberInput.current.focus()
        }
      },
      onError: (error) => {
        setIsSendButtonDisabled(false)
        setServerErrorMessage(getErrorMessage(error))
      },
      fetchPolicy: 'no-cache',
    }
  )
  if (renderType === RenderType.FIND_ID) {
    return (
      <>
        <Row justify="center" style={{ paddingTop: 28, paddingBottom: 11 }}>
          <Col>
            <Text40Normal bold>아이디 찾기</Text40Normal>
          </Col>
        </Row>
        <Row justify="center" style={{ paddingBottom: 40 }}>
          <Col>
            <Text18Normal>휴대폰 번호 인증을 통해 이메일 아이디를 찾을 수 있습니다</Text18Normal>
          </Col>
        </Row>
        <Form
          form={formOfNameMobileNumber}
          size="large"
          layout="vertical"
          requiredMark={false}
          onFinish={() => {
            useConfirmSendCertModalProps.showModal()
          }}
        >
          <MFormItemInput inputStyle={{ height: 50 }} {...FORM_ITEM_PROPS_USERNAME} />
          <MFormItemInput
            inputStyle={{ height: 50 }}
            {...FORM_ITEM_PROPS_MOBILE_NUMBER}
            onChange={(e) => {
              setIsValidMobileNumber(utilFormValidators.isValidMobileNumberFormat(e.target.value))
            }}
          />
          <Row style={{ paddingTop: 4, paddingBottom: 28 }}>
            <Col span={24}>
              <MButton
                htmlType="submit"
                theme="black"
                height={70}
                width="100%"
                // loading={userByNameMobileNumberLoading || sendUserCertNumberLoading}
                loading={sendUserCertNumberLoading}
                disabled={!isValidMobileNumber || isSendButtonDisabled}
                fontSize={{ xs: 14, md: 18 }}
              >
                {isCertNumberSent ? '인증번호 재전송' : '인증번호 전송'}
              </MButton>
              {/* TODO yoon: ModalConfirm 으로 변경 필요 */}
              <MConfirmModal
                useModalProps={useConfirmSendCertModalProps}
                fixedProps={{
                  theme: 'blackAndWhite',
                  closable: true,
                  description: (
                    <Text14Normal>
                      {utilData.formatPhoneNumber(
                        formOfNameMobileNumber.getFieldValue(FORM_ITEM_PROPS_MOBILE_NUMBER.name)
                      )}{' '}
                      로 인증번호를 전송합니다.
                    </Text14Normal>
                  ),
                }}
                onAction={() => {
                  setServerErrorMessage('')
                  formOfNameMobileNumber.validateFields().then((values) => {
                    setIsSendButtonDisabled(true)
                    if (!sendUserCertNumberLoading) {
                      setUserName(values.name)
                      sendUserCertNumberMutation({
                        variables: MUTATION_VARIABLES.SEND_USER_CERT_NUMBER({
                          mobileNumber: values.mobileNumber,
                          certType: CERT_TYPE.FIND_ID_BY_MOBILE_NUMBER,
                        }),
                      }).catch((error) => {
                        console.log('error,,', error)
                      })
                    }
                  })
                }}
              />
            </Col>
          </Row>
        </Form>

        <MMutation
          gqlKey="findId"
          // @ts-ignore - resultType을 재정의 하기 위해서 ignore함
          onAPISuccess={(result: { userInfo: { data: { id: string; email: string } } }) => {
            const { userInfo } = result
            setUserEmail(userInfo.data.email)
            setRenderType(RenderType.FOUND_ID)
          }}
        >
          {(
            mutation: MutationFunction,
            { loading, error: errorFindId }: { loading: boolean; error: { message?: string } }
          ) => {
            return (
              <Form
                form={formOfCertNumber}
                requiredMark={false}
                onFinish={async (values) => {
                  if (userCertification) {
                    setServerErrorMessage('')
                    const variables = MUTATION_VARIABLES.FIND_ID({
                      userCertification: userCertification.id,
                      confirmNumber: values.mobileConfirmNumber,
                    })
                    try {
                      await mutation({ variables })
                    } catch (e) {
                      console.log(e)
                    }
                  }
                }}
              >
                <Row justify="space-between">
                  <Col span={15}>
                    <MFormItemInput
                      disabled={!isCertNumberSent}
                      inputStyle={{ height: 40 }}
                      inputRef={refCertNumberInput}
                      onChange={(e) => {
                        setIsCertButtonDisabled(
                          e.target.value.length !== FORM_ITEM_PROPS_MOBILE_CONFIRM_NUMBER.maxLength
                        )
                      }}
                      suffix={
                        <Statistic.Countdown
                          format="mm:ss"
                          // @ts-ignore - Statistic.Countdown에서 Moment type을 허용 안함. 하지만 공식 문서에서는 Moment는 쓸 수 있다고 함
                          value={userCertification?.expiredAt}
                          valueStyle={{ fontSize: 14 }}
                          onFinish={() => {
                            setUserCertification(undefined)
                            setIsCertButtonDisabled(true)
                            formOfCertNumber.setFieldsValue({ [FORM_ITEM_PROPS_MOBILE_CONFIRM_NUMBER.name]: '' })
                          }}
                        />
                      }
                      {...FORM_ITEM_PROPS_MOBILE_CONFIRM_NUMBER}
                    />
                  </Col>
                  <Col span={8}>
                    <MButton
                      theme="white"
                      htmlType="submit"
                      height={40}
                      width="100%"
                      fontSize={14}
                      disabled={isCertButtonDisabled}
                      loading={loading}
                    >
                      인증번호 확인
                    </MButton>
                  </Col>
                </Row>
                <Row
                  justify="center"
                  hidden={serverErrorMessage === '' && errorFindId?.message === undefined}
                  style={{ marginBottom: 19 }}
                >
                  <Col>
                    <Text14Red>{serverErrorMessage || getErrorMessage(errorFindId?.message)}</Text14Red>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </MMutation>

        <Row justify="center" style={{ paddingTop: 24 }}>
          <Col>
            <MLinkButton url={ROUTES.FIND_PASSWORD} textUnderline={false}>
              <Text12Grey81>비밀번호 찾기</Text12Grey81>
            </MLinkButton>
          </Col>
          <Col>
            <Divider type="vertical" style={{ borderColor: themes.colors.grey55 }} />
          </Col>
          <Col>
            <MLinkButton url={ROUTES.LOGIN} textUnderline={false}>
              <Text12Grey81>로그인 하기</Text12Grey81>
            </MLinkButton>
          </Col>
        </Row>
      </>
    )
  }

  // renderType === RenderType.FOUND_ID
  return (
    <>
      <Row justify="center" style={{ paddingTop: 28, paddingBottom: 40 }}>
        <Col>
          <Text40Normal bold>아이디 확인</Text40Normal>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Text14Normal bold>{userName} 회원님의 아이디는 아래와 같습니다.</Text14Normal>
        </Col>
      </Row>
      <Row justify="center" style={{ paddingTop: 28 }}>
        <StyledBorderCol span={24}>
          <Text20Normal>{userEmail}</Text20Normal>
        </StyledBorderCol>
      </Row>
      <Row justify="center" style={{ paddingTop: 48 }}>
        <Col>
          <MLinkButton url={ROUTES.FIND_PASSWORD} textUnderline={false}>
            <Text12Grey81>비밀번호 찾기</Text12Grey81>
          </MLinkButton>
        </Col>
        <Col>
          <Divider type="vertical" style={{ borderColor: themes.colors.grey55 }} />
        </Col>
        <Col>
          <MLinkButton url={ROUTES.LOGIN} textUnderline={false}>
            <Text12Grey81>로그인 하기</Text12Grey81>
          </MLinkButton>
        </Col>
      </Row>
    </>
  )
}

const StyledBorderCol = styled(Col)`
  border: 2px solid ${themes.colors.black33};
  height: 70px;
  line-height: 70px;
  text-align: center;
`

export default FormFindId
