import { FC } from 'react'
import { Checkbox } from 'antd'
import { CheckboxProps } from 'antd/lib/checkbox'
import styled from 'styled-components'

import { Text } from 'components/@atomics'

export type InputCheckboxProps = {
  text: string
} & CheckboxProps

const MInputCheckbox: FC<InputCheckboxProps> = ({ text, ...props }) => {
  return (
    <StyledCheckbox {...props}>
      <Text size={{ xs: 12, md: 14 }}>{text}</Text>
    </StyledCheckbox>
  )
}

const StyledCheckbox = styled(Checkbox)``

export default MInputCheckbox
