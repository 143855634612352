import { ModalConfirm, ModalConfirmInput } from 'domains/common/modals'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Row } from 'antd'
import { MButton } from 'components/@atomics'
import { useRouter } from 'next/router'
import useModal from 'containers/hooks/useModal'
import { IModelStatus } from 'containers/models/modelStatus'
import { doRefetchMainTable } from '@libs/redux/reduxUI'
import ROUTES from '@constants/constRoutes'
import { MUTATION_KEY2GQLS } from 'containers/gqls'

type DButtonsApproveOrRejectProps = {
  status?: IModelStatus
  target: 'BRAND' | 'SELLER' | 'PRODUCT'
  isUpdateRequest?: boolean
  hasNoRequiredField?: boolean
  gplKey?: string
  variableKey?: string
  getCustomVariables?: () => Promise<any>
}

type TargetText = 'SELLER' | 'BRAND' | 'PRODUCT'
type GetTargetText = (target: TargetText) => '셀러' | '브랜드' | '상품' | '-'

const getGqlKey = (isApprove: boolean, isUpdateRequest: boolean) => {
  if (isApprove) {
    return isUpdateRequest ? 'approveUpdateRequest' : 'approveRequest'
  }
  return isUpdateRequest ? 'rejectUpdateRequest' : 'rejectRequest'
}

const getButtonText = (isApprove: boolean, isUpdateRequest: boolean) => {
  if (isApprove) {
    return isUpdateRequest ? '변경요청 승인' : '등록요청 승인'
  }
  return isUpdateRequest ? '변경요청 반려' : '등록요청 반려'
}

const getTargetText: GetTargetText = (target) => {
  if (target === 'SELLER') return '셀러'
  if (target === 'BRAND') return '브랜드'
  if (target === 'PRODUCT') return '상품'
  return '-'
}

const getTexts = (isApprove: boolean, isUpdateRequest: boolean, target: TargetText) => {
  const targetText = getTargetText(target)
  const buttonText = getButtonText(isApprove, isUpdateRequest)
  const suffixText = isApprove ? '승인' : '반려'
  let requestTypeText = isUpdateRequest ? '정보변경 요청' : '등록 요청'
  requestTypeText = target === 'PRODUCT' ? '정보 수정' : requestTypeText
  let messageText = `${requestTypeText}이 ${suffixText}되었습니다.`
  if (!isUpdateRequest && isApprove) {
    if (target === 'SELLER') {
      messageText += ' 승인된 셀러는 "셀러 리스트"에서 확인할 수 있습니다.'
    }
  }
  const res = {
    title: `${targetText} ${requestTypeText} ${suffixText}`,
    description: `해당 ${targetText}의 ${requestTypeText}을 ${suffixText}합니다.`,
    buttonText,
    messageText,
  }
  return res
}

const DButtonsApproveOrReject: FC<DButtonsApproveOrRejectProps> = ({
  status,
  target,
  isUpdateRequest = false,
  hasNoRequiredField = false,
  gplKey = undefined,
  variableKey = undefined,
  getCustomVariables = undefined,
}) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { useModalProps: approveModalProps } = useModal()
  const { useModalProps: rejectModalProps } = useModal()
  const btnWidth: number = 120

  const goToList = () => {
    const { query = {} } = router
    const { from } = query
    if (from) {
      router.replace(from as string)
    } else {
      if (target === 'SELLER') router.push(ROUTES.PARTNERS.SELLER_LIST)
      else if (target === 'BRAND') router.push(ROUTES.PARTNERS.BRAND_LIST)
      else if (target === 'PRODUCT') router.push(ROUTES.PRODUCTS.PRODUCT_LIST)
    }
  }

  const {
    title: _title,
    description: _description,
    buttonText: _buttonText,
    messageText: _messageText,
  } = getTexts(true, isUpdateRequest, target)
  const { title, description, buttonText, messageText } = getTexts(false, isUpdateRequest, target)
  return (
    <Row justify="end" gutter={16} style={{ marginRight: 20 }}>
      <Col>
        <MButton onClick={() => goToList()} width={80}>
          취소
        </MButton>
      </Col>
      <Col>
        <MButton
          onClick={() => {
            rejectModalProps.toggleModal()
            rejectModalProps.setData({ status })
          }}
          width={btnWidth}
        >
          {buttonText}
        </MButton>
        <ModalConfirmInput
          useModalProps={rejectModalProps}
          fixedProps={{
            mutationKey: {
              gqlKey: getGqlKey(false, isUpdateRequest),
              variableKey: getGqlKey(false, isUpdateRequest),
            },
            title,
            description,
            inputOption: {
              name: 'message',
              type: 'input',
              label: '반려사유:',
              placeholder: '반려 사유를 입력해 주세요.',
              required: true,
            },
            confirmMessage: messageText,
          }}
          onAPISuccess={() => {
            dispatch(doRefetchMainTable())
            goToList()
          }}
        />
      </Col>
      <Col>
        <MButton
          onClick={() => {
            approveModalProps.toggleModal()
            approveModalProps.setData({ status })
          }}
          type="primary"
          width={btnWidth}
          disabled={hasNoRequiredField}
        >
          {_buttonText}
        </MButton>
        <ModalConfirm
          useModalProps={approveModalProps}
          fixedProps={{
            mutationKey: {
              gqlKey:
                gplKey !== undefined ? (gplKey as keyof typeof MUTATION_KEY2GQLS) : getGqlKey(true, isUpdateRequest),
              variableKey: variableKey !== undefined ? variableKey : 'statusId',
              getCustomVariables,
            },
            title: _title,
            description: _description,
            confirmMessage: _messageText,
          }}
          onAPISuccess={() => {
            dispatch(doRefetchMainTable())
            goToList()
          }}
        />
      </Col>
    </Row>
  )
}

export default DButtonsApproveOrReject
