import _ from 'lodash'
import { useState } from 'react'
import { Col, Form, Row } from 'antd'
import { MutationFunction } from '@apollo/client'
import { useRouter } from 'next/router'
import * as utilApi from '@libs/utils/utilApi'
import * as utilCrypto from '@libs/utils/utilCrypto'
import MMutation from 'components/MMutation'
import ROUTES from '@constants/constRoutes'
import { CERT_TYPE, CLIENT_TYPE, FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import { FORM_ITEM_RULES } from '@constants/constForm'
import { MButton, MLinkRouterButton, Text14G55, Text14G97, Text14Red, Text24Normal } from 'components/@atomics'
import { MFormItemInput, MFormItemWrapper } from 'components/formItems'
import { IModelUserCertification } from 'containers/models/modelUserCertification'
import { IModelFakeWhoami } from 'containers/models/modelFakeWhoami'
// import useUserInfo from 'containers/hooks/useUserInfo'
import { FormCertNumber } from 'domains/common/forms'
import MInputCheckbox from 'components/@atomics/inputs/MInputCheckbox'

const FORM_ITEM_PROPS_USERNAME = {
  label: FIELD_VALUES.FIELD_EMAIL_ID,
  name: FIELD_KEYS.FIELD_EMAIL,
  type: 'email',
  placeholder: '아이디(이메일)를 입력해 주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_EMAIL_ID],
}

const FORM_ITEM_PROPS_PASSWORD = {
  type: FIELD_KEYS.FIELD_PASSWORD,
  name: FIELD_KEYS.FIELD_PASSWORD,
  label: FIELD_VALUES.FIELD_PASSWORD,
  placeholder: '비밀번호를 입력해 주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_PASSWORD_FORMAT],
}

const FORM_ITEM_PROPS_CONFIRM_PASSWORD = {
  type: FIELD_KEYS.FIELD_PASSWORD,
  name: FIELD_KEYS.FIELD_CONFIRM_PASSWORD,
  placeholder: '비밀번호를 다시한번 입력해 주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_CONFIRM_PASSWORD],
}

const FORM_ITEM_PROPS_NAME = {
  label: FIELD_VALUES.FIELD_NAME,
  name: FIELD_KEYS.FIELD_NAME,
  placeholder: '이름을 입력해주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
}

const FORM_ITEM_PROPS_USER_AGREEMENT_AGREE_PRIVACY_POLICY = {
  name: FIELD_KEYS.FIELD_AGREE_PRIVACY_POLICY,
  valuePropName: 'checked',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_AGREE_PRIVACY_POLICY],
}

const FormSignup = () => {
  const router = useRouter()
  // 사용자 정보 입력
  const [formInputUserInfo] = Form.useForm()
  const [isAllUserInfoInputted, setIsAllUserInfoInputted] = useState(false)
  const [confirmedCertData, setConfirmedCertData] = useState<{
    isConfirmed: boolean
    userCertificationData?: IModelUserCertification
  }>({
    isConfirmed: false,
    userCertificationData: undefined,
  })
  // 약관 동의 여부
  const [isAgreedPrivacyPolicy, setIsAgreedPrivacyPolicy] = useState(false)

  // const { saveTokenNWhoami } = useUserInfo.useSaveUserInfo()

  return (
    <>
      <Row justify="center" style={{ paddingTop: 14 }}>
        <Col>
          <Text24Normal>브랜드 매니저 가입</Text24Normal>
        </Col>
      </Row>

      <MMutation
        gqlKey="emailSignup"
        onAPISuccess={(data: IModelFakeWhoami | any) => {
          if (!data) return
          // TODO yoon: 브랜드 매니저는 가입 후, 승인 절차가 있기 때문에 아래 코드가 필요 없을 것 같음. 기획 확인 필요
          // const { device } = data
          // const { token } = device.data
          // const { userMode } = user.data
          // saveTokenNWhoami(token, data)
          // saveUserMode(userMode)

          // if (token) {
          //   router.push({
          //     pathname: ROUTES.REGISTER_BRAND_MANAGER,
          //     query: { isShowingSignUpText: true },
          //   })
          // }
          router.push({
            pathname: ROUTES.REGISTER_BRAND_MANAGER,
            query: { isShowingSignUpText: true },
          })
        }}
      >
        {(mutation: MutationFunction, { loading, error }: { loading: boolean; error: { message?: string } }) => {
          return (
            <Form
              form={formInputUserInfo}
              size="large"
              layout="vertical"
              requiredMark={false}
              onValuesChange={() => {
                setIsAllUserInfoInputted(
                  !_.isEmpty(formInputUserInfo.getFieldValue(FORM_ITEM_PROPS_USERNAME.name)) &&
                    !_.isEmpty(formInputUserInfo.getFieldValue(FORM_ITEM_PROPS_PASSWORD.name)) &&
                    !_.isEmpty(formInputUserInfo.getFieldValue(FORM_ITEM_PROPS_NAME.name))
                )
              }}
              onFinish={() => {
                formInputUserInfo
                  .validateFields()
                  .then(async (values) => {
                    await mutation({
                      variables: {
                        input: {
                          user: {
                            [FIELD_KEYS.FIELD_USER_CERTIFICATION]: confirmedCertData.userCertificationData?.id,
                            [FIELD_KEYS.FIELD_EMAIL]: values.email,
                            [FIELD_KEYS.FIELD_NAME]: values.name,
                            [FIELD_KEYS.FIELD_PASSWORD]: values.password,
                          },
                          device: {
                            clientType: CLIENT_TYPE.WEB,
                            uuid: utilCrypto.createDeviceUuid(),
                          },
                        },
                      },
                    })
                  })
                  .catch((e) => {
                    console.log('formInputUserInfo.validateFields error', e)
                  })
              }}
            >
              <MFormItemInput style={{ marginBottom: 28 }} inputStyle={{ height: 50 }} {...FORM_ITEM_PROPS_USERNAME} />
              <MFormItemInput style={{ marginBottom: 15 }} inputStyle={{ height: 50 }} {...FORM_ITEM_PROPS_PASSWORD} />
              <MFormItemInput
                style={{ marginBottom: 0 }}
                inputStyle={{ height: 50 }}
                {...FORM_ITEM_PROPS_CONFIRM_PASSWORD}
              />
              <Text14G97>· 영문대/소문자,숫자,특수문자 중 2가지 이상 조합, 8~16자 이내</Text14G97>
              <MFormItemInput
                style={{ marginTop: 28, marginBottom: 28 }}
                inputStyle={{ height: 50 }}
                {...FORM_ITEM_PROPS_NAME}
              />
              <FormCertNumber
                certType={CERT_TYPE.NEW_USER}
                onConfirmed={(isConfirmed, resultData) => {
                  setConfirmedCertData({
                    isConfirmed,
                    userCertificationData: resultData.userCertification,
                  })
                }}
              />

              <MFormItemWrapper {...FORM_ITEM_PROPS_USER_AGREEMENT_AGREE_PRIVACY_POLICY}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <MInputCheckbox
                      text="(필수) 개인정보 수집 및 이용 동의"
                      onChange={(e) => {
                        setIsAgreedPrivacyPolicy(e.target.checked)
                      }}
                    />
                  </Col>
                  <Col>
                    <MLinkRouterButton href={ROUTES.POLICIES.PRIVACY} openNewWindow>
                      <Text14G55 style={{ textDecoration: 'underline' }}>보기</Text14G55>
                    </MLinkRouterButton>
                  </Col>
                </Row>
              </MFormItemWrapper>

              <MFormItemWrapper noStyle>
                <Row justify="space-between" style={{ marginTop: 30 }} gutter={10}>
                  <Col span={12}>
                    <MButton
                      theme="white"
                      width="100%"
                      height={60}
                      onClick={() => {
                        router.push(ROUTES.LOGIN)
                      }}
                    >
                      취소
                    </MButton>
                  </Col>
                  <Col span={12}>
                    <MButton
                      disabled={!isAllUserInfoInputted || !confirmedCertData.isConfirmed || !isAgreedPrivacyPolicy}
                      htmlType="submit"
                      loading={loading}
                      theme="black"
                      width="100%"
                      height={60}
                    >
                      확인
                    </MButton>
                  </Col>
                </Row>
              </MFormItemWrapper>

              <Row justify="center" hidden={error?.message === undefined} style={{ marginBottom: 19 }}>
                <Col span={24}>
                  <Text14Red>{utilApi.getErrorMessage(error?.message)}</Text14Red>
                </Col>
              </Row>
            </Form>
          )
        }}
      </MMutation>
    </>
  )
}

export default FormSignup
