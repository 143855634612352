import { MutationFunction } from '@apollo/client'
import { PET_TYPE, PET_TYPE_TEXT, USER_MODE_TYPE } from '@constants/constDomain'

import { FORM_ITEM_RULES } from '@constants/constForm'
import * as utilData from '@libs/utils/utilData'
import { Col, Form, message, Row } from 'antd'
import { FormInstance } from 'antd/es/form'
import { MButton, MOuterCard, Text14Normal } from 'components/@atomics'
import { MFormItemInput, MFormItemRadio, MFormItemWrapper } from 'components/formItems'
import MFormItemSingleImageUpload from 'components/formItems/MFormItemSingleImageUpload'
import MMutation from 'components/MMutation'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import useUserInfo from 'containers/hooks/useUserInfo'
import { IModelBrand } from 'containers/models/modelBrand'
import { IModelSeller } from 'containers/models/modelSeller'
import DButtonsApproveOrReject from 'domains/admin/DButtonsApproveOrReject'
import ButtonSelectFromTable from 'domains/common/ButtonSelectFromTable'
import { useRouter } from 'next/router'
import { Store } from 'rc-field-form/lib/interface'
import { FIELD_KEYS, FIELD_VALUES } from '@constants/constData'

const FORM_ITEM_BRAND = {
  NAME: {
    label: '브랜드명',
    name: FIELD_KEYS.FIELD_NAME,
    required: true,
  },
  PET_TYPE: {
    label: FIELD_VALUES.FIELD_PET_TYPE,
    name: FIELD_KEYS.FIELD_PET_TYPE,
    options: [
      { label: PET_TYPE_TEXT.ALL, value: PET_TYPE.ALL },
      { label: PET_TYPE_TEXT.DOG, value: PET_TYPE.DOG },
      { label: PET_TYPE_TEXT.CAT, value: PET_TYPE.CAT },
    ],
    required: true,
  },
  LIST_IMAGE: {
    label: '브랜드 목록 이미지',
    name: FIELD_KEYS.FIELD_LIST_IMAGE,
    required: true,
    accept: '.jpg,.jpeg,.png',
    rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_IMAGE_FILESIZE],
    option: { width: 200, height: 200 },
    comment: '* 브랜드 목록에 노출되는 이미지입니다.\n* 크기 310 x 310 Pixel 이상 가로 세로 정비율 이미지만 등록 가능',
  },
  BACKGROUND_IMAGE: {
    label: '브랜드 홈 이미지',
    name: 'backgroundImage',
    required: false,
    accept: '.jpg,.jpeg,.png',
    rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_IMAGE_FILESIZE],
    comment: '* 크기 1125 x 660 Pixel',
  },
}

type FormBrandBasicProps = {
  form: FormInstance
  userMode: USER_MODE_TYPE
  seller?: IModelSeller
  disabled?: boolean
}

const FormBrandBasic = ({ disabled, form, seller, userMode }: FormBrandBasicProps) => {
  const data = seller ? [seller] : undefined
  return (
    <MOuterCard title="브랜드 기본 정보" requiredMark>
      <MFormItemInput {...FORM_ITEM_BRAND.NAME} disabled={disabled} wrapperCol={{ span: 6 }} />
      <MFormItemRadio {...FORM_ITEM_BRAND.PET_TYPE} disabled={disabled} />

      {userMode === USER_MODE_TYPE.ADMIN && disabled && (
        <MFormItemWrapper label="운영 셀러" labelCol={{ span: 3 }} wrapperCol={{ span: 6 }}>
          <Text14Normal>{seller?.companyName}</Text14Normal>
        </MFormItemWrapper>
      )}
      {userMode === USER_MODE_TYPE.ADMIN && !disabled && (
        <MFormItemWrapper name="seller" label="운영 셀러">
          <ButtonSelectFromTable
            data={data}
            modelType="seller"
            title="셀러 선택"
            displayTag
            onAction={(values: IModelSeller[]) => {
              const sellerId = utilData.hasData(values) ? values[0].id : undefined
              form.setFieldsValue({ seller: sellerId })
            }}
          />
        </MFormItemWrapper>
      )}
    </MOuterCard>
  )
}

type FormExposureInfoProps = {
  brandData?: IModelBrand
  disabled?: boolean
}

const FormExposureInfo = ({ disabled }: FormExposureInfoProps) => {
  return (
    <MOuterCard title="브랜드 노출 정보">
      <MFormItemSingleImageUpload {...FORM_ITEM_BRAND.LIST_IMAGE} disabled={disabled} />
      <MFormItemSingleImageUpload {...FORM_ITEM_BRAND.BACKGROUND_IMAGE} disabled={disabled} />
    </MOuterCard>
  )
}

type FormBrandProps = {
  brandData?: IModelBrand
  userMode: USER_MODE_TYPE
}

const query2initialValues = (brandData?: IModelBrand, hasUpdateInfo?: boolean) => {
  if (!brandData) return { petType: PET_TYPE.ALL }

  const _brandData = hasUpdateInfo ? brandData.updateInfo.brand : brandData
  let petType = hasUpdateInfo ? brandData.petType : _brandData.petType
  if (!petType) petType = PET_TYPE.ALL
  return {
    ...(_brandData! as unknown as Store),
    petType,
    listImage: hasUpdateInfo ? brandData.updateInfo.listImage : _brandData.listImage,
    backgroundImage: hasUpdateInfo ? brandData.updateInfo.backgroundImage : _brandData.backgroundImage,
    homeImage: hasUpdateInfo ? brandData.updateInfo.homeImage : _brandData.homeImage,
    seller: utilData.hasData(brandData.sellers) ? brandData.sellers[0].id : '',
  }
}

const getGqlKey = (userMode: USER_MODE_TYPE, isEdit: boolean) => {
  if (userMode === USER_MODE_TYPE.ADMIN) {
    if (isEdit) return 'updateBrandByFitpetAdmin'
    return 'createBrandByFitpetAdmin'
  }
  if (isEdit) return 'updateBrandBySeller'
  return 'createBrandBySeller'
}

const FormBrand = ({ brandData, userMode }: FormBrandProps) => {
  const router = useRouter()
  const [form] = Form.useForm()
  const isEdit = !!brandData
  const { currentRoleInfo } = useUserInfo.useCurrentUserData()
  const hasUpdateInfo = utilData.hasData(brandData) && utilData.hasData(brandData!.updateInfo)
  const disabled = hasUpdateInfo && brandData?.updateStatus?.isRequest
  // 1. 액션 버튼이 하나도 없는 경우 - 셀러가 정보변경 request 상태에서 볼 때
  // 2. 승인 / 반려 버튼이 있는 경우 - 어드민이 정보변경 request 상태에서 볼 때
  // 3. 그외 - 취소 / 저장
  const isAdmin = userMode === USER_MODE_TYPE.ADMIN
  const noActionButton = !isAdmin && brandData?.updateStatus?.isRequest
  const needUpdateStatusAction = isAdmin && brandData?.updateStatus?.isRequest
  const needStatusAction = isAdmin && brandData?.status?.isRequest
  const initialValues = query2initialValues(brandData, hasUpdateInfo)

  const sellerData =
    userMode === USER_MODE_TYPE.ADMIN && isEdit && utilData.hasData(brandData!.sellers)
      ? brandData!.sellers[0]
      : undefined
  const gqlKey = getGqlKey(userMode, isEdit)

  const handleFinish = (values: Store, mutation: MutationFunction) => {
    const variables = MUTATION_VARIABLES.CREATE_BRAND_BY_FITPET_ADMIN({ ...values })
    if (isEdit) {
      const { id } = brandData!
      variables.id = id
    }
    if (userMode === USER_MODE_TYPE.SELLER) {
      variables.input.seller = currentRoleInfo.key
    }
    mutation({ variables })
  }

  return (
    <MMutation
      gqlKey={gqlKey}
      onAPISuccess={() => {
        const msg = isAdmin ? '브랜드 정보가 수정/등록 되었습니다.' : '브랜드 정보가 수정 요청되었습니다'
        message.success(msg)
        router.back()
      }}
    >
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
        return (
          <>
            <Form
              name="formBrand"
              form={form}
              initialValues={initialValues}
              onFinish={(values) => handleFinish(values, mutation)}
              labelCol={{ span: 3 }}
            >
              <FormBrandBasic disabled={disabled} form={form} seller={sellerData as IModelSeller} userMode={userMode} />
              <FormExposureInfo disabled={disabled} />
              {!noActionButton && !needUpdateStatusAction && !needStatusAction && (
                <Row justify="end" gutter={16} style={{ marginRight: 20 }}>
                  <Col>
                    <MButton
                      onClick={() => {
                        router.back()
                      }}
                      width={80}
                    >
                      취소
                    </MButton>
                  </Col>
                  <Col>
                    <MButton type="primary" width={80} htmlType="submit" form="formBrand" loading={loading}>
                      {isAdmin ? '저장' : '승인 요청'}
                    </MButton>
                  </Col>
                </Row>
              )}
            </Form>
            {!noActionButton && needStatusAction && (
              <DButtonsApproveOrReject target="BRAND" status={brandData?.status} />
            )}
            {!noActionButton && needUpdateStatusAction && (
              <DButtonsApproveOrReject target="BRAND" status={brandData?.updateStatus} isUpdateRequest />
            )}
          </>
        )
      }}
    </MMutation>
  )
}

export default FormBrand
