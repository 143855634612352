import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { antdPickerLocale, MFormItemRadio, MFormItemWrapper } from 'components'
import { Col, DatePicker, Row } from 'antd'
import { MFormItemRadioProps } from 'components/formItems/MFormItemRadio'
import { MFormBelowComment } from 'components/@atomics/forms'
import { FormInstance } from 'antd/lib/form'
import { getToday } from '@libs/utils/utilDate'

type RadioProps = {
  name: string
} & MFormItemRadioProps

type MFormLimitedInputProps = {
  form: FormInstance
  isAbleToShow?: boolean
  formItemProps: MFormItemWrapperProps & { comment?: string }
  radioProps: RadioProps
  datePickerProps: { name: string; format?: string; picker?: string; placeholder?: string }
}

const MFormRadioYnWithDatePicker: React.FC<MFormLimitedInputProps> = (props) => {
  const { form, isAbleToShow = true, formItemProps: _formItemProps, radioProps, datePickerProps } = props
  const { comment, ...formItemProps } = _formItemProps

  const handleChangeRadio = (selected: boolean) => {
    if (selected) {
      form.setFieldsValue({ [datePickerProps.name]: getToday(), [radioProps.name]: true })
      return
    }
    form.setFieldsValue({ [datePickerProps.name]: undefined, [radioProps.name]: false })
  }

  return isAbleToShow ? (
    <MFormItemWrapper
      {...formItemProps}
      {...(!comment && { style: { marginBottom: 0 } })}
      shouldUpdate={(prevValues, currentValues) => prevValues[radioProps.name] !== currentValues[radioProps.name]}
    >
      {({ getFieldValue }) => {
        return (
          <>
            <Row>
              <Col>
                <MFormItemRadio {...radioProps} onChange={handleChangeRadio} />
              </Col>
              <Col>
                <MFormItemWrapper {...datePickerProps}>
                  {/**@ts-ignore */}
                  <DatePicker
                    locale={antdPickerLocale}
                    format={datePickerProps?.format || 'YYYY-MM'}
                    picker={datePickerProps?.picker || 'month'}
                    placeholder={datePickerProps?.placeholder || '날짜를 선택하세요'}
                    disabled={!getFieldValue(radioProps.name)}
                  />
                </MFormItemWrapper>
              </Col>
            </Row>
            {comment ? (
              <Row>
                <MFormBelowComment comment={comment} />
              </Row>
            ) : null}
          </>
        )
      }}
    </MFormItemWrapper>
  ) : null
}

export default MFormRadioYnWithDatePicker
