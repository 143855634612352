import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { MutationFunction } from '@apollo/client'
import { Divider, Form, Row, Col } from 'antd'

import { themes } from '@constants'
import * as utilApi from '@libs/utils/utilApi'
import * as utilLocalStorage from '@libs/utils/utilLocalStorage'
import { IModelFakeWhoami } from 'containers/models/modelFakeWhoami'
import {
  CLIENT_TYPE,
  FITPET_CS_EMAIL,
  FITPET_CS_TEL,
  FIELD_KEYS,
  FIELD_VALUES,
  REGISTER_BRAND_URL,
} from '@constants/constData'
import { FORM_ITEM_RULES } from '@constants/constForm'
import ROUTES from '@constants/constRoutes'
import { MButton, MDivider, Text12Grey81, Text14Black33, Text14G99, Text14Red } from 'components/@atomics'
import { MLinkButton, MLinkRouterButton } from 'components/@atomics/buttons'
import MMutation from 'components/MMutation'
import useUserInfo from 'containers/hooks/useUserInfo'
import { MFormItemInput } from 'components'
import MInputCheckbox from 'components/@atomics/inputs/MInputCheckbox'
import * as utilCrypto from '@libs/utils/utilCrypto'

const FORM_ITEM_PROPS_USERNAME = {
  label: FIELD_VALUES.FIELD_EMAIL_ID,
  name: FIELD_KEYS.FIELD_EMAIL_ID,
  type: 'email',
  placeholder: '아이디(이메일)를 입력해 주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_EMAIL_ID],
}

const FORM_ITEM_PROPS_PASSWORD = {
  label: FIELD_VALUES.FIELD_PASSWORD,
  name: FIELD_KEYS.FIELD_PASSWORD,
  type: 'password',
  placeholder: '비밀번호를 입력해 주세요',
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
}

const FormSignin = () => {
  const [form] = Form.useForm()
  const router = useRouter()
  const { saveTokenNWhoami } = useUserInfo.useSaveUserInfo()
  const [rememberUserId, setRememberUserId] = useState<string>('')
  const [isRememberUserIdChecked, setRememberUserIdChecked] = useState<boolean>(false)
  const rememberedUserId = utilLocalStorage.localRememberUserId.load()

  useEffect(() => {
    if (rememberedUserId) {
      setRememberUserId(rememberedUserId)
      setRememberUserIdChecked(true)
    }
  }, [])

  const onAPISuccess = async (data: IModelFakeWhoami | any) => {
    if (!data) return

    const { device } = data
    const { token } = device.data

    if (isRememberUserIdChecked) {
      utilLocalStorage.localRememberUserId.save(rememberUserId)
    } else {
      utilLocalStorage.localRememberUserId.remove()
    }

    if (token) {
      const {
        user: { data: user },
      } = data
      if (!user.isStaff && !user.isSeller) {
        router.push(ROUTES.REGISTER_BRAND_MANAGER)
        return
      }

      saveTokenNWhoami(token, data)
      router.push({ pathname: ROUTES.DASHBOARD, query: null })
    }
  }

  const onFinish = (values: { emailId: string; password: string }, mutation: MutationFunction) => {
    const { emailId, password } = values
    mutation({
      variables: {
        input: {
          user: { email: emailId, password },
          device: {
            clientType: CLIENT_TYPE.WEB,
            uuid: utilCrypto.createDeviceUuid(),
            keepSignIn: isRememberUserIdChecked,
          },
        },
      },
    })
  }

  const onUserIdChange = async (e: any) => {
    const userId = e.target.value
    setRememberUserId(userId)
  }

  return (
    <MMutation gqlKey="emailSignin" onAPISuccess={onAPISuccess}>
      {(
        mutation: MutationFunction,
        { loading, error: serverError }: { loading: boolean; error: { message?: string } }
      ) => {
        return (
          <>
            <Row justify="center" style={{ paddingTop: 16, paddingBottom: 41 }}>
              <Col>
                <Text14G99>핏펫몰 관리자 페이지입니다.</Text14G99>
              </Col>
            </Row>
            <Form
              form={form}
              scrollToFirstError
              size="large"
              layout="vertical"
              requiredMark={false}
              initialValues={{
                [FIELD_KEYS.FIELD_EMAIL]: undefined,
                [FIELD_KEYS.FIELD_PASSWORD]: undefined,
              }}
              onFinish={(values) => onFinish(values, mutation)}
            >
              <MFormItemInput
                inputStyle={{ height: 50 }}
                {...FORM_ITEM_PROPS_USERNAME}
                initialValue={rememberedUserId}
                onChange={onUserIdChange}
              />
              <MFormItemInput inputStyle={{ height: 50 }} {...FORM_ITEM_PROPS_PASSWORD} />
              <Row hidden={serverError?.message === undefined} style={{ marginBottom: 19 }}>
                <Col>
                  <Text14Red>{utilApi.getErrorMessage(serverError?.message)}</Text14Red>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <MInputCheckbox
                    text="아이디 저장"
                    checked={isRememberUserIdChecked}
                    onChange={async (e) => {
                      setRememberUserIdChecked(e.target.checked)
                    }}
                  />
                </Col>
                <Col>
                  <Row gutter={[0, 10]}>
                    <Col>
                      <MLinkButton url={ROUTES.FIND_ID} textUnderline={false}>
                        <Text12Grey81>아이디 찾기</Text12Grey81>
                      </MLinkButton>
                    </Col>
                    <Col>
                      <Divider type="vertical" style={{ borderColor: themes.colors.grey55 }} />
                    </Col>
                    <Col>
                      <MLinkButton url={ROUTES.FIND_PASSWORD} textUnderline={false}>
                        <Text12Grey81>비밀번호 찾기</Text12Grey81>
                      </MLinkButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <MButton
                theme="black"
                htmlType="submit"
                loading={loading}
                height={70}
                width="100%"
                fontSize={{ xs: 14, md: 18 }}
              >
                로그인
              </MButton>
            </Form>

            <Row style={{ paddingTop: 16 }}>
              <Col span={24}>
                <MButton
                  theme="white"
                  height={50}
                  width="100%"
                  fontSize={{ xs: 10, md: 14 }}
                  onClick={() => {
                    router.push(ROUTES.SIGNUP)
                  }}
                >
                  브랜드 매니저 가입
                </MButton>
              </Col>
            </Row>

            <MDivider style={{ marginTop: 40, marginBottom: 28, borderColor: themes.colors.black }} />

            {/*<Text14Black33 bold>브랜드 셀러 안내</Text14Black33>*/}
            {/*<Row style={{ marginTop: 11 }}>*/}
            {/*  <Col>*/}
            {/*    <Text14Black33>*/}
            {/*      {`입점을 원하는 브랜드는 입점 문의를 해주세요.*/}
            {/*        입점한 브랜드의 매니저로 등록하고자하는 경우 ‘브랜드 매니저 가입’ 후 브랜드 관리자에게 요청해 주세요.`}*/}
            {/*    </Text14Black33>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row style={{ marginTop: 16, marginBottom: 39 }}>*/}
            {/*  <Col style={{ width: 160 }}>*/}
            {/*    <MLinkRouterButton*/}
            {/*      href={REGISTER_BRAND_URL}*/}
            {/*      openNewWindow*/}
            {/*    >*/}
            {/*      <MButton*/}
            {/*        theme="white"*/}
            {/*        height={40}*/}
            {/*        width={160}*/}
            {/*        fontSize={14}*/}
            {/*      >*/}
            {/*        브랜드 입점 문의*/}
            {/*      </MButton>*/}
            {/*    </MLinkRouterButton>*/}
            {/*  </Col>*/}
            {/*</Row>*/}

            {/*<MDivider style={{ marginTop: 40, marginBottom: 28, borderColor: themes.colors.black }} />*/}

            <Row justify="center">
              <Col>
                <Text14Black33 align="center">
                  관리자 문의: {FITPET_CS_TEL}, {FITPET_CS_EMAIL}
                </Text14Black33>
              </Col>
            </Row>
          </>
        )
      }}
    </MMutation>
  )
}
export default FormSignin
